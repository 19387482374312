import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Page from '../components/Page';
import SEO from '../components/seo';

const PageTemplate = ({ pageContext: context, uri }) => {
  const {
    featuredImage, content, title, seo,
  } = context;
  // Set to Default Background Image
  const backgroundImage = featuredImage
    ? featuredImage.sourceUrl
    : '';


  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        type="article"
        image={backgroundImage}
        url={uri}
      />
      <Page.Wrapper>
        <Page.Image img={backgroundImage} />
        <Page.Content top>
          <Page.Title>{title}</Page.Title>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Page.Content>
      </Page.Wrapper>
    </Layout>
  );
};

PageTemplate.defaultProps = {};
PageTemplate.propTypes = {
  uri: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    featuredImage: PropTypes.shape({
      sourceUrl: PropTypes.string,
    }),
    content: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default PageTemplate;
